import { DeleteDish } from '../components/Admin/Restaurant/DeleteDish';

interface Props {
  
}

const RemoveDish = (props: Props) => {
  return (
    <DeleteDish>
      
    </DeleteDish>
  )
}

export default RemoveDish
