export const data = [
  {
    name: "Mon",
    orders: 40,
  },
  {
    name: "Tue",
    orders: 30,
  },
  {
    name: "Wed",
    orders: 20,
  },
  {
    name: "Thu",
    orders: 27,
  },
  {
    name: "Fri",
    orders: 18,
  },
  {
    name: "Sat",
    orders: 23,
  },
  {
    name: "Sun",
    orders: 34,
  },
];
